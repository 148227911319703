import React, { useState, useEffect } from 'react';
import { RedocStandalone } from 'redoc';
import { Grid, useTheme, CircularProgress, Table, TableContainer, TableCell, TableHead, TableRow, TableBody, Paper, Tabs, Tab, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Base64 } from 'js-base64';

const Documentation: React.FC<{ user: any }> = (props) => {

  const [openapi, setOpenAPI] = useState();
  const [changes, setChanges] = useState();
  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const fetchOpenAPISpec = React.useCallback(() => {
    const fetchData = async () => {
      let data = await fetch('/api/v1/doc/meta/', {
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          token: props.user.getAuthResponse().id_token
        }),
        method: "POST"
      });
      if (!data.ok) {
        setOpenAPI(null);
      } else {
        // let result = await data.json();
        let result = await data.text();
        if (result) {
          setOpenAPI(result);
        }
      }
    }
    fetchData();
  }, [props.user]);

  const fetchChangeLog = React.useCallback(() => {
    const fetchData = async () => {
      let data = await fetch('/api/v1/doc/changes/', {
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          token: props.user.getAuthResponse().id_token
        }),
        method: "POST"
      });
      if (!data.ok) {
        setChanges(null);
      } else {
        let result = await data.json();
        if (result) {
          setChanges(result);
        }
      }
    }
    fetchData();
  }, [props.user]);

  useEffect(() => {
    fetchOpenAPISpec();
    fetchChangeLog();
  }, [fetchOpenAPISpec, fetchChangeLog]);

  const content = () => {
    if (openapi === null) {
      return (<Alert severity="error">Cannot retrieve the Documentation at this time.</Alert>);
    } else if (!openapi) {
      return (<CircularProgress />);
    }
    return (<RedocStandalone specUrl={"data:application/yaml;base64," + Base64.encode(openapi)}></RedocStandalone>);
  }

  const handleTabChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <Tabs value={tab} onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Integration" />
        <Tab label="Changelog" />
        <Tab label="Additional Information" />
      </Tabs>
      {tab === 0 &&
        (
          <React.Fragment>
            <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Developer Documentation</h2>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={2} style={{ padding: '20px' }}>
              <Grid item xs={12}>
                {content()}
              </Grid>
            </Grid>
          </React.Fragment>
        )
      }
      {tab === 1 &&
        (
          <React.Fragment>
            <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Service Changelog</h2>
            <TableContainer component={Paper} style={{ padding: '20px' }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Change</TableCell>
                    <TableCell align="right">Author</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {changes && changes.changelog.map((change: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        {new Date(change.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{change.message}</TableCell>
                      <TableCell align="right">{change.author}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )
      }
      {tab === 2 &&
        (
          <React.Fragment>
            <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Information</h2>
            <Grid container direction="column" spacing={2} style={{ padding: '20px' }}>
              <Grid item xs={12}>
                <p><Link href="https://docs.google.com/document/d/1NFCQgMFEMrYntVrpytGAyLyPYhwQmUH4RC0ngw5810k/edit?usp=sharing" target="_blank" rel="noopener">Getting started guide on Google Drive</Link></p>
                <p><Link href="static/EventDefinitionManagerAPI.postman_collection.json" download="EventDefinitionManagerAPI.postman_collection.json">Postman file for Event API</Link></p>
              </Grid>
            </Grid>
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
}

export default Documentation;