import React from 'react';
import { Typography, Card, CardContent, Grid, Paper, useTheme } from '@material-ui/core';
import logo from '../UPG_RGB_Landscape_White.svg';

const Login: React.FC<{ user: any, doSignIn: (user: any) => void }> = (props) => {

  React.useEffect(() => {
    if (!props.user) {
      window.gapi.signin2.render('g-signin2', { theme: 'dark', longtitle: "true", onsuccess: props.doSignIn })
    }
  }, [props.user, props.doSignIn])

  const theme = useTheme();

  return (
    <Grid container className="AppBackground">
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid container justify="center" style={{ padding: '20px' }}>
            <Grid item xs={8} style={{ maxWidth: '800px', minHeight: '100%' }}>
              <Card style={{ backgroundColor: 'transparent' }}>
                <CardContent style={{ backgroundColor: 'rgba(50, 43, 120, 0.75)' }}>
                  <img src={logo} alt="Unifiedpost Group" />
                  <Typography style={{ color: '#fff' }}>Analyze and Gather insights for important Key Performance Indicators.</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} style={{ maxWidth: '400px', minHeight: '100%' }}>
              <Paper style={{ height: '100%' }}>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ padding: '20px' }}>
                  <Grid item>
                    <h2 style={{ color: theme.palette.primary.main }}>Login</h2>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justify="center" alignItems="center" style={{ paddingTop: '80px' }}>
                      <Grid item>
                        <Typography style={{ fontSize: '11pt' }}>Please sign in with your Corporate Employee Account.</Typography>
                      </Grid>
                      <Grid item>
                        <div id="g-signin2" data-onsuccess="onSignIn" data-longtitle="true" data-theme="dark" style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid >
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;