import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Grid, CircularProgress } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import OverviewDashboards from './OverviewDashboards';
import DisplayDashboard from './DisplayDashboard';
import { ICumulInfo } from '../rest/types';

interface IMessage {
  severity: Color,
  content: string
}

const CumulDashboard: React.FC<{ user: any }> = (props) => {

  const [cumulInfo, setCumulInfo] = useState<ICumulInfo>();
  const [timer, setTimer] = useState<any>();
  const [message, setMessage] = React.useState<IMessage>();
  const { path, url } = useRouteMatch();

  const fetchCumulData = React.useCallback(() => {
    if (props.user) {
      const fetchData = async () => {
        let data = await fetch('/api/v1/dashboards', {
          headers: {
            "Content-Type": "application/json; charset=UTF-8"
          },
          body: JSON.stringify({
            token: props.user.getAuthResponse().id_token
          }),
          method: "POST"
        });
        if (!data.ok) {
          if (data.status === 401) {
            setMessage({ severity: 'error', content: 'You do not appear to have enough permissions to view these dashboards. Access can be requested through an IT Helpdesk ticket.' })
          }
          else {
            setMessage({ severity: 'error', content: 'Unable to fetch dashboard data ' + data.statusText });
          }
        }
        let result = await data.json() as ICumulInfo;
        if (result) {
          if (result.dashboard) {
            result.dashboard.sort((a, b): number => {
              return a?.name.localeCompare(b?.name);
            });
          }
          setCumulInfo(result);
          setTimer(setTimeout(() => {
            fetchCumulData();
          }, Math.max(result.expiry - new Date().getTime() - 30000, 0)));
        }
      }
      fetchData();
    }
  }, [props.user]);

  useEffect(() => {
    fetchCumulData();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [props.user, fetchCumulData]);

  return (
    <React.Fragment>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2} style={{ paddingTop: "20px" }}>
        <Grid item xs={12}>
          {message ? (
            <Alert severity={message?.severity as Color}>{message?.content}</Alert>
          ) : (
              ""
            )}
        </Grid>
        {cumulInfo && cumulInfo.dashboard ?
          (
            <Switch>
              <Route path={`${path}/:dashboardId`}>
                <DisplayDashboard user={props.user} cumulInfo={cumulInfo}></DisplayDashboard>
              </Route>
              <Route exact path={path}>
                <OverviewDashboards cumulinfo={cumulInfo}></OverviewDashboards>
              </Route>
            </Switch>
          ) : (
            < Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )
        }
      </Grid>
    </React.Fragment >
  );
}

export default CumulDashboard;