import React, { useState, useEffect } from 'react';
import './App.css';
import CssBaseLine from '@material-ui/core/CssBaseline';
import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import { BrowserRouter as Router, Link as RouterLink, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import logo from './UPG_RGB_Landscape_White.svg'
import Login from './components/Login';
import Body from './components/Body';
import Error404 from './components/Error404'
import TopRightBarEntries from './components/TopRightBarEntries';
import { SnackbarProvider } from 'notistack';

declare global {
  interface Window {
    gapi: any;
    Cumulio: any;
  }
}

const App: React.FC = () => {
  const [googleClientID, setGoogleClientID] = useState();
  const [googleUser, setGoogleUser] = useState();

  const signin = (googleUser: any) => {
    if (googleUser) {
      let profile = googleUser.getBasicProfile();
      console.info("User signed in as: " + profile.getEmail());
      setGoogleUser(googleUser);
    }
  };

  const signout = async () => {
    let auth2 = window.gapi.auth2.getAuthInstance();
    try {
      await auth2.signOut();
      setGoogleUser(null);
      console.info("User signed out");
    }
    catch (err) {
      console.log("Unable to sign out user - ", err);
    }
  }

  const fetchGoogleClientID = async () => {
    if (!googleClientID) {
      let data = await fetch('/api/v1/gid', {
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        method: "GET"
      });
      if (!data.ok) {
        console.log('Unable to access data, server said ' + data.status + '(' + data.statusText + ')');
      }
      let result = await data.json();
      if (result) {
        window.gapi.load('auth2', () => {
          window.gapi.auth2.init({ client_id: result.id });
          setGoogleClientID(result.id);
        })
      }
    }
  }

  useEffect(() => {
    fetchGoogleClientID();
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63c6bf',
        main: '#322b78',
        dark: '#211c4e',
        contrastText: '#ffffff',
      }
    },
    typography: {
      fontFamily: [
        'Lato',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  if (!googleClientID) {
    return (<div className="App" />);
  } else if (!googleUser) {
    return (
      <div className="App">
        <React.Fragment>
          <CssBaseLine />
          {
            <ThemeProvider theme={theme}>
              <Login user={googleUser} doSignIn={signin} />
            </ThemeProvider>
          }
        </React.Fragment>
      </div >
    );
  } else {
    return (
      <div className="App">
        <React.Fragment>
          <CssBaseLine />
          {
            <React.Fragment>
              <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={5}>
                  <Router>
                    <Switch>
                      <Route path="/404">
                        <Error404 />
                      </Route>
                      <Route path="/">
                        <Grid container>
                          <Grid item xs={12}>
                            <AppBar position="static">
                              <Toolbar>
                                <IconButton edge="start" color="inherit" aria-label="menu">
                                  <RouterLink to="/" style={{ textDecoration: 'none', color: '#fff' }}>
                                    <AppsIcon fontSize="large"/>
                                  </RouterLink>
                                </IconButton>
                                <RouterLink to="/" style={{ textDecoration: 'none', color: '#fff' }}>
                                  <img src={logo} alt="Unifiedpost KPIs" width="220" />
                                </RouterLink>
                                <div style={{ flexGrow: 1 }}></div>
                                <TopRightBarEntries user={googleUser} doSignOut={signout} />
                              </Toolbar>
                            </AppBar>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container alignItems="center">
                              <Body user={googleUser} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Route>
                    </Switch>
                  </Router>
                </SnackbarProvider>
              </ThemeProvider>
            </React.Fragment>
          }
        </React.Fragment>
      </div >
    );
  }
}

export default App;
