import React, { useState, useEffect } from 'react';
import { useTheme, Table, TableContainer, TableCell, TableHead, TableRow, TableBody, Paper, Tabs, Tab, CircularProgress, Select, MenuItem, Button, Container, Typography, Grid, Checkbox, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useSnackbar } from 'notistack';
import { IACL } from '../rest/types';

const Authorization: React.FC<{ user: any }> = (props) => {

  const [acl, setACL] = useState<IACL | null>();
  const [tab, setTab] = useState(0);
  const [selected, setSelected] = useState(new Set<string>());
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const fetchACL = React.useCallback(() => {
    const fetchData = async () => {
      let data = await fetch('/api/v1/acl/', {
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          token: props.user.getAuthResponse().id_token
        }),
        method: "POST"
      });
      if (!data.ok) {
        setACL(null);
      } else {
        let result = await data.json() as IACL;
        if (result) {
          setACL(result);
        }
      }
    }
    fetchData();
  }, [props.user]);

  useEffect(() => {
    fetchACL();
  }, [fetchACL]);

  const handleTabChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  const changeRole = (user: string, newrole: string) => {
    const newacl: IACL = {
      grants: {
        ...acl?.grants,
      },
      users: {
        ...acl?.users,
      }
    }
    newacl.users[user] = newrole;
    setACL(newacl);
    console.log(`User ${user} is now assigned role ${newrole}`);
  };

  const saveChanges = () => {
    const saveData = async () => {
      let data = await fetch('/api/v1/acl/', {
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          token: props.user.getAuthResponse().id_token,
          acl: acl
        }),
        method: "PUT"
      });
      if (!data.ok) {
        enqueueSnackbar(`Unable to save changes - ${data.statusText} (${data.status})`, { variant: 'error' });
      } else {
        enqueueSnackbar("Changes saved", { variant: 'success' });
      }
    }
    saveData();
  };

  const resetChanges = () => {
    fetchACL();
  };

  const select = (user: string) => {
    const items = new Set(selected);
    items.add(user);
    setSelected(items)
  };

  const deselect = (user: string) => {
    const items = new Set(selected);
    items.delete(user);
    setSelected(items)
  };

  const addNewUser = (user: string, role: string) => {
    if (user) {
      const newacl: IACL = {
        grants: {
          ...acl?.grants,
        },
        users: {
          ...acl?.users,
        }
      }
      newacl.users[user] = role;
      setACL(newacl);
    }
  }

  const handleDelete = () => {
    const newacl: IACL = {
      grants: {
        ...acl?.grants,
      },
      users: {
        ...acl?.users,
      }
    }
    selected.forEach(item => delete newacl.users[item]);
    setSelected(new Set());
    setACL(newacl);
  }

  const updateGrant = (role: string, resource: string, permission: string, allowed: string) => {
    console.log("Updating ", role, ":", resource, ":", permission, " with ", allowed);
    const newacl: IACL = {
      grants: {
        ...acl?.grants,
      },
      users: {
        ...acl?.users,
      }
    }
    newacl.grants[`${role}`][`${resource}`][`${permission}`] = allowed.split(",").map(s => s.trim());
    setACL(newacl);
  }

  if (acl === null) {
    return (<Alert severity="error">You are not authorized to access these pages.</Alert>);
  } else if (!acl) {
    return (<CircularProgress />);
  }

  const roles = Array.from(new Set(Object.keys(acl.grants)));

  const AddUser: React.FC<{ addNewUser: (user: string, role: string) => void }> = (props) => {
    const [addUser, setAddUser] = useState<string>('');
    const [addRole, setAddRole] = useState<string>('');

    const handleAdd = () => {
      if (addUser && addRole) {
        addNewUser(addUser, addRole);
        setAddUser('');
        setAddRole('');
      }
    }

    return (
      <React.Fragment>
        <Grid container justify="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" size="small" startIcon={<AddCircleOutlineOutlinedIcon />} onClick={e => handleAdd()}>Add</Button>
          </Grid>
        </Grid>
        <Paper style={{ padding: "20px" }}>
          <TextField value={addUser} required label="Email Address" helperText="Specify the new users email address in the corporate domain." placeholder="some.one@unifiedpost.com" fullWidth onChange={e => setAddUser(e.target.value)} />
          <TextField value={addRole} required select label="Role" helperText="Select a security role controlling the users permissions." fullWidth onChange={e => setAddRole(e.target.value as string)}>
            {roles?.map(r => (
              <MenuItem key={r} value={r}>{r}</MenuItem>
            ))}
          </TextField>
        </Paper>
      </React.Fragment>
    );
  };

  return (
    <Container maxWidth="xl">
      <Tabs value={tab} onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="User Management" />
        <Tab label="Role Management" />
      </Tabs>
      {tab === 0 &&
        (
          <React.Fragment>
            <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Users Whitelist</h2>
            {
              <AddUser addNewUser={addNewUser} />
            }
            <Grid container justify="flex-end" style={{ paddingTop: "20px" }}>
              <Grid item style={{ paddingRight: "10px" }}>
                <Button variant="contained" color="primary" size="small" startIcon={<DeleteForeverOutlinedIcon />} onClick={handleDelete} >Delete</Button>
              </Grid>
              <Grid item style={{ paddingRight: "10px" }}>
                <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon />} onClick={saveChanges} >Save</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="small" startIcon={<RotateLeftIcon />} onClick={resetChanges}>Reset</Button>
              </Grid>
            </Grid>
            <TableContainer component={Paper} style={{ padding: "20px" }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell><Typography variant="subtitle1">User</Typography></TableCell>
                    <TableCell><Typography variant="subtitle1">Role</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acl?.users && Object.entries(acl.users).map(([user, role]: [string, any], index) => (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        <Checkbox checked={selected.has(user)} onChange={e => e.target.checked ? select(user) : deselect(user)} value="primary" />
                      </TableCell>
                      <TableCell>
                        {user}
                      </TableCell>
                      <TableCell>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={role} onChange={e => changeRole(user, e.target.value as string)}>
                          {roles?.map(r => (
                            <MenuItem key={r} value={r}>{r}</MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )
      }
      {tab === 1 &&
        (
          <React.Fragment>
            <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Authorization Roles</h2>
            <Grid container justify="flex-end" style={{ paddingTop: "20px" }}>
              <Grid item style={{ paddingRight: "10px" }}>
                <Button variant="contained" color="primary" size="small" startIcon={<SaveIcon />} onClick={saveChanges} >Save</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="small" startIcon={<RotateLeftIcon />} onClick={resetChanges}>Reset</Button>
              </Grid>
            </Grid>
            <TableContainer component={Paper} style={{ paddingTop: "20px" }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Role</TableCell>
                    <TableCell>Rights and Permissions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acl?.grants && Object.entries(acl.grants).map(([grant, values], index: number) => (
                    <TableRow key={`grant-${index}`}>
                      <TableCell align='left' scope="row">
                        {grant}
                      </TableCell>
                      <TableCell>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {Object.entries(values).map(([resource, permission], index: number) => (
                              <TableRow key={`resource-${index}`}>
                                <TableCell align="left" style={{ width: '150px' }}>
                                  {resource}
                                </TableCell>
                                <TableCell align="left">
                                  <Grid container direction="column" style={{ width: '100%' }} justify="flex-start" spacing={2}>
                                    {Object.entries(permission).map(([permission, allowed], index: number) => (
                                      <Grid item key={`permission-${index}`}>
                                        <Grid container direction="row" spacing={4}>
                                          <Grid item style={{ minWidth: "130px" }}>
                                            {permission}
                                          </Grid>
                                          <Grid item>
                                            <TextField id="outlined-basic" variant="outlined" multiline size="small" value={allowed.toString()} style={{ minWidth: "900px" }} onChange={e => updateGrant(grant, resource, permission, e.target.value)} />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )
      }
    </Container>
  );
}

export default Authorization;