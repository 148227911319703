import React from 'react';
import { Grid, List, ListItem, ListItemText, ListItemIcon, Paper, Divider, Select, MenuItem, useTheme } from '@material-ui/core';
import { ICumulInfo } from '../rest/types';
import { Link, LinkProps } from 'react-router-dom';

const groupByTags = (arr: ICumulInfo['dashboard']) => {
  return arr.reduce((acc: any, cur: { id: string, name: string, tags: string[] }) => {
    if (cur.tags.length === 0) {
      if (!acc['Other']) {
        acc['Other'] = { 'default': [] }
      }
      acc['Other']['default'] = [...acc['Other']?.default || [], cur];
    } else {
      for (let i = 0; i < cur.tags.length; ++i) {
        let [tag, regiontag = 'default'] = cur.tags[i].split(' - ');
        if (!acc[tag]) {
          acc[tag] = {};
        }
        acc[tag][regiontag] = [...acc[tag]?.[regiontag] || [], cur];
      }
    }
    return acc;
  }, {});
}

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;

  const theme = useTheme();

  const renderLink = React.useMemo(() =>
    React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
      <Link to={to} ref={ref} {...itemProps} />
    )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primaryTypographyProps={{ color: 'textPrimary' }} primary={primary} />
      </ListItem>
    </li>
  );
}

const OverviewDashboards: React.FC<{ cumulinfo: ICumulInfo }> = (props) => {

  const theme = useTheme();

  // Calculate the dashboard groups, subgroups and initial selections for the tag groups
  const tagmap = groupByTags(props.cumulinfo.dashboard);
  const initialselections: { [tag: string]: string } = {};
  Object.keys(tagmap).forEach((tag) => {
    initialselections[tag] = Object.keys(tagmap[tag]).sort()[0];
  });
  const [selections, setSelections] = React.useState(initialselections);

  const handleChange = (tag: string, event: React.ChangeEvent<{ value: unknown }>) => {
    const newselection = {
      ...selections
    };
    newselection[tag] = event.target.value as string;
    setSelections(newselection);
  };

  if (!props.cumulinfo?.dashboard) {
    return (<div></div>);
  }

  return (
    <React.Fragment>
      <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>Available KPI Reports</h2>
      <Grid container spacing={3} style={{ padding: '20px' }}>
        {Object.keys(tagmap).sort().map((tag, tagindex) => (
          <Grid key={`tag-${tagindex}`} item xs={3}>
            <Paper variant="outlined" square elevation={3} style={{ height: '100%' }}>
              <Grid container>
                <Grid item style={{ width: '100%' }}>
                  <h2 style={{ color: theme.palette.primary.main, paddingTop: "20px" }}>{tag}</h2>
                  <Divider variant="middle" />
                  {Object.keys(tagmap[tag]).length > 1 &&
                    (<Select style={{ fontWeight: "bold", color: theme.palette.primary.main, marginTop: "20px", marginBottom: "20px" }} key={`select-${tagindex}`} labelId="subtag-select-label" id="subtag-select" value={selections[tag as string] || Object.keys(tagmap[tag]).sort()[0]} onChange={e => handleChange(tag, e)}>
                      {Object.keys(tagmap[tag]).sort().map((subtag, subtagindex) => (
                        <MenuItem key={`subtag-${tagindex}.${subtagindex}`} value={subtag}>{subtag}</MenuItem>
                      ))}
                    </Select>
                    )
                  }
                  <Divider variant="middle" />
                  {Object.keys(tagmap[tag]).sort().map((subtag, subtagindex) => (
                    <React.Fragment key={`list-${tagindex}.${subtagindex}`}>
                      {Object.keys(tagmap[tag]).length > 1 ?
                        selections[tag] === subtag &&
                        (<Grid container justify="center">
                          <Grid item>
                            <List>
                              {tagmap[tag][subtag].map((dashboard: { id: string, name: string }, index: number) => (
                                <ListItemLink key={`dash-${tagindex}.${subtagindex}.${index}`} to={`/dashboards/${dashboard.id}`} primary={dashboard.name} />
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                        ) : (<Grid container justify="center">
                          <Grid item>
                            <List>
                              {tagmap[tag][subtag].map((dashboard: { id: string, name: string }, index: number) => (
                                <ListItemLink key={`dash-${tagindex}.${subtagindex}.${index}`} to={`/dashboards/${dashboard.id}`} primary={dashboard.name} />
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                        )
                      }
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default OverviewDashboards;