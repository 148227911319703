import React from 'react';
import { Grid } from '@material-ui/core';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Documentation from './Documentation';
import CumulDashboard from './CumulDashboard';
import Authorization from './Authorization';

const Body: React.FC<{ user: any; }> = (props) => {

  let match = useRouteMatch();
  return (<React.Fragment>
    <Grid item xs={12}>
      <Switch>
        <Route path={`${match.path}docs`}>
          <Documentation user={props.user} />
        </Route>
        <Route path={`${match.path}admin`}>
          <Authorization user={props.user} />
        </Route>
        <Route exact path={`${match.path}dashboards*`}>
          <CumulDashboard user={props.user} />
        </Route>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}dashboards`}/>
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Grid>
    <Grid item xs={12}>
      <p>All content on this website is considered confidential. Copyright Unifiedpost group - all rights reserved.</p>
    </Grid>
  </React.Fragment >);
};

export default Body;