const sendToServer = (json: { type: string, level: string; message: string }) => {
  fetch('/api/v1/client/log', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(json),
  })
    // swallow any errors with logging so we do not get an endless loop.
    // eslint-disable-next-line
    .catch((err) => { });
};

export const logMessage = (message: string, level: string): void => {
  sendToServer({
    type: 'log',
    level,
    message
  });
}

export const logAnalytic = (message: string, analytic: {}): void => {
  const json = {
    type: 'analytic',
    level: 'info',
    message: message,
    event: analytic
  };
  sendToServer(json);
}

export const logUncaughtJavaScriptErrors = () => {
  window.addEventListener('error', (event: ErrorEvent) => {
    const { message, filename, lineno, colno } = event;
    const json = {
      type: 'log',
      level: 'error',
      message,
      fatal: true,
      url: filename,
      lineNumber: lineno,
      column: colno,
    };

    sendToServer(json);
  });
};

export const logUnhandledRejections = () => {
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    logMessage(`Unhandled Rejection Fired: ${event.reason}`, 'error');
  });
};

export const interceptConsole = (level: 'info' | 'warning' | 'error' = 'warning'): void => {
  switch (level) {
    // eslint-disable-next-line
    case 'info': {
      const origInfo = console.info;
      console.info = (...args: any[]) => {
        logMessage(args.join(' '), 'info');
        origInfo(args);
      };
      // No Break
    }
    // eslint-disable-next-line
    case 'warning': {
      const origWarn = console.warn;
      console.warn = (...args: any[]) => {
        logMessage(args.join(' '), 'warning');
        origWarn(args);
      };
      // No Break
    }
    // eslint-disable-next-line
    default: {
      const origError = console.error;
      console.error = (...args: any[]) => {
        logMessage(args.join(' '), 'error');
        origError(args);
      };
    }
  }
};