import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, InputLabel, Select, Grid } from '@material-ui/core';
import * as LogClient from '../util/logclient';
import { ICumulInfo } from '../rest/types';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

const DisplayDashboard: React.FC<{ user: any, cumulInfo: ICumulInfo }> = (props) => {

  const { path, url } = useRouteMatch();
  const { dashboardId } = useParams();
  const history = useHistory();
  const [selectedDashboard, setSelectedDashBoard] = useState(dashboardId);

  useEffect(() => {
    if (selectedDashboard && selectedDashboard !== '') {
      if (document.querySelector('#dashboard')?.hasChildNodes()) {
        window.Cumulio.removeDashboard({
          container: '#dashboard'
        });
      }

      window.Cumulio.addDashboard({
        dashboardId: selectedDashboard,
        container: '#dashboard',
        key: props.cumulInfo.id,
        token: props.cumulInfo.token
      });

      let selected = props.cumulInfo.dashboard.find((obj: any) => {
        return obj.id === selectedDashboard
      })
      let user = props.user.getBasicProfile().getEmail();
      LogClient.logAnalytic("Show dashboard: " + selected?.name + " to: " + user, { type: 'showdashboard', dashboard: selected?.name, user: user });
    }
  }, [selectedDashboard, props.user, props.cumulInfo])

  const selectoptions = [];
  if (props.cumulInfo && props.cumulInfo.dashboard) {
    for (const dashboard of props.cumulInfo.dashboard) {
      selectoptions.push(<MenuItem key={dashboard.id} value={dashboard.id}>{dashboard.name}</MenuItem>);
    }
  }

  const handleSelection = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDashBoard(event.target.value as string)
    history.push(url.substr(0, url.lastIndexOf('/')) + '/' + event.target.value);
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormControl>
          <InputLabel id="dashboard-select-label">Select Dashboard</InputLabel>
          <Select labelId="dashboard-select-label" id="dashboard-select" onChange={handleSelection} value={selectedDashboard}>
            {selectoptions}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div key="cumuldash" id="dashboard" />
      </Grid>
    </React.Fragment >
  );
}

export default DisplayDashboard;