import React from 'react';
import { IconButton, Menu, MenuItem, Avatar } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const TopRightBarEntries: React.FC<{ user: any; doSignOut: () => void; }> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const linkdocs = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>((props, ref) => <RouterLink innerRef={ref} to="/docs" {...props} />);
  const linkadmin = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'innerRef' | 'to'>>((props, ref) => <RouterLink innerRef={ref} to="/admin" {...props} />);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    handleClose();
    props.doSignOut();
  };

  const handleProfile = async () => {
    handleClose();
    window.open("https://myaccount.google.com/", "_blank");
  };

  if (!props.user) {
    return (<div></div>);
  }
  return (<React.Fragment>
    <IconButton color="inherit" component={linkdocs}>
      <HelpOutlineIcon />
    </IconButton>
    <IconButton color="inherit" component={linkadmin}>
      <SettingsOutlinedIcon />
    </IconButton>
    <IconButton edge="end" color="inherit" aria-controls="menu-account" aria-haspopup="true" onClick={handleMenu}>
      <Avatar alt={props.user.getBasicProfile().getName()} src={props.user.getBasicProfile().getImageUrl()} style={{ margin: 10 }} />
    </IconButton>
    <Menu id="menu-account" anchorEl={anchorEl} keepMounted open={anchorEl ? true : false} onClose={handleClose}>
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  </React.Fragment>);
};

export default TopRightBarEntries;