import React from 'react';
import { Typography, Card, CardContent, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const Error404: React.FC<{}> = (props) => {

  return (
    <Grid container className="AppBackground">
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid container justify="center" style={{ padding: '20px' }}>
            <Grid item xs={8} style={{ maxWidth: '800px', minHeight: '100%' }}>
              <Card style={{ backgroundColor: 'transparent' }}>
                <CardContent style={{ backgroundColor: 'rgba(50, 43, 120, 0.75)' }}>
                  <h2 style={{ color: '#fff' }}>Page not found.</h2>
                  <Typography style={{ color: '#fff' }}>Sorry, we could not find the page you are looking for. Go back <RouterLink to="/" style={{ color: '#fff' }}>home</RouterLink>.</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid >
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Error404;